import React from 'react';
import './Contact.css';
import Contact1Allimg from './Allimg/Contact1.mp4';
import WhatsupLogo from '../src/Logo/Whatsup.png';

function Contact() {
  return (  
    <div id='main0'>
      <div className="video-container">

        <video className='vid' src={Contact1Allimg} autoPlay muted loop></video>

        <div className="form-overlay">
          <h1>Contact Us</h1>

          <form>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>

      <a href="https://wa.me/7772927777" target="_blank" rel="noopener noreferrer">
      <img src={WhatsupLogo} alt="" width="50px" className="whatsapp_btn" />
    </a>

    

{/* <header class="ScriptHeader">
    <div class="rt-container">
    	<div class="col-rt-12">
        	<div class="rt-heading">
            	<h1>Responsive Contact Us Page Example</h1>
                <p>A responsive contact us page design.</p>
            </div>
        </div>
    </div>
</header> */}

{/* <section>
    <div class="rt-container">
          <div class="col-rt-12">
              <div class="Scriptcontent">
              
               <div>
            <div class="container">
                <div class="contact-parent">
                    <div class="contact-child child1">
                        <p>
                            <i class="fas fa-map-marker-alt"></i> Address <br />
                            <span> Ash Lane 110
                                <br />
                                London, UK
                            </span>
                        </p>

                        <p>
                            <i class="fas fa-phone-alt"></i> Let's Talk <br />
                            <span> 0787878787</span>
                        </p>

                        <p>
                            <i class=" far fa-envelope"></i> General Support <br />
                            <span>contact@example.com</span>
                        </p>
                    </div>

                    <div class="contact-child child2">
                        <div class="inside-contact">
                            <h2>Contact Us</h2>
                            <h3>
                               <span id="confirm"/>
                            </h3>

                            <p>Name *</p>
                            <input id="txt_name" type="text" Required="required"/>

                            <p>Email *</p>
                            <input id="txt_email" type="text" Required="required"/>

                            <p>Phone *</p>
                            <input id="txt_phone" type="text" Required="required"/>

                            <p>Subject *</p>
                            <input id="txt_subject" type="text" Required="required"/>

                            <p>Message *</p>
                            <textarea id="txt_message" rows="4" cols="20" Required="required" ></textarea>
                            
                            <input type="submit" id="btn_send" value="SEND"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    		
           
    		</div>
		</div>
    </div>
    
</section>
     */}
    </div>
  );
}

export default Contact;
